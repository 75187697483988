import React from "react";
import Employee from "../Employee/Employee";
import { Heading5, Subtitle2 } from "../Typography/Typography";
import styles from "./KommuneNotFound.module.scss";

const KommuneNotFound = ({ mainEmployee, kommuneName, kommuneNumber }) => {
  return (
    <div className={styles.doesNotExist}>
      <Heading5>
        FLYT-programmet finnes ikke i {kommuneName} kommune enda
      </Heading5>
      <Subtitle2>
        Hvis du ønsker å få Flytprogrammet til {kommuneName} kommune, ta kontakt
        med {mainEmployee.name.split(" ")[0]}, vår {mainEmployee.position} for å
        høre mer:
      </Subtitle2>
      <br />
      <Employee {...mainEmployee} />
    </div>
  );
};

export default KommuneNotFound;
