import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { graphql, navigate, useStaticQuery } from "gatsby";
import KommuneNotFound from "../components/KommuneNotFound/KommuneNotFound";
import SEO from "../components/seo";

const KommuneNotFoundPage = ({ location }) => {
  const {
    sanityContactPage: { employees },
  } = useStaticQuery(GET_MAIN_EMPLOYEE);
  const mainEmployee = employees[0];
  const { name, nr } = (location.state && location.state.kommune) || {};
  useEffect(() => {
    if (!name && typeof window !== undefined) navigate("/404");
  }, [location]);
  return (
    <Layout location={location} standardPadding maxWidth>
      <SEO title="FLYT-programmet finnes ikke i denne kommunen enda" />
      <KommuneNotFound
        mainEmployee={mainEmployee}
        kommuneName={name}
        kommuneNumber={nr}
      />
    </Layout>
  );
};

const GET_MAIN_EMPLOYEE = graphql`
  query {
    sanityContactPage(_id: { regex: "/contactPage$/" }) {
      employees: mainEmployees {
        name
        position
        phone
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        email
      }
    }
  }
`;

export default KommuneNotFoundPage;
